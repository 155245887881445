<script setup lang="ts">
import UserSettingsBaseContainer from './user-settings-base-container.vue';
import Button from '~/components/design-system/button.vue';
import Toast from 'primevue/toast';
import { onClickOutside } from '@vueuse/core';
import { useSiteStore } from '~/stores/site.store';
import { useLocaleStore } from '../../stores/locale.store';
import GenericError from '~/components/user-interface/generic-error.vue';
import { trackingMethods } from '~~/plugins/analytics.client';
import { useToast } from 'primevue/usetoast';
import { toastPassThrough } from '~/components/passthroughs/toast';
import type { VueformSchema } from '@vueform/vueform/core';

const { $t } = useNuxtApp();
const localeStore = useLocaleStore();

interface IPersonalDetails {
  email: string;
  username: string;
  firstname: string;
  lastname: string;
  languageOption: string;
  dialingCode: string;
  countryCode: string;
  idNumberType?: string;
  idNumber?: number | string;
  signupCode?: string;
  referralCode?: string;
  sourceOfFunds: string;
  dateOfBirth: Date;
  sourceOfIncome: string;
}

interface IFieldErrors {
  firstname: string;
  lastname: string;
  dateOfBirth: string;
  email: string;
  language: string;
  idNumber: string;
  idNumberType: string;
  sourceFunds: string;
}

interface IIdTypeOptions {
  id: string;
  description: string;
}

interface Ilanguages {
  key: string;
  value: string;
}

interface IErrorsObject {
  isEmpty: string;
  isEmailValid: string;
  emptyString: string;
}

const langOptions = ref();
const toast = useToast();
let mobileNo: string[] = reactive([]);
const primaryNo = ref<string>('');
const active = ref<boolean>(false);
const { $authService } = useServices();
const errorsObject = ref<IErrorsObject>({
  isEmpty: 'This field cannot be empty.',
  isEmailValid: 'Please provide a valid email address.',
  emptyString: '',
});
const activeLoader = ref<boolean>(false);
const userDetails = ref<IPersonalDetails>({
  email: '',
  username: '',
  firstname: '',
  lastname: '',
  languageOption: '',
  dialingCode: '',
  countryCode: '',
  idNumberType: '',
  idNumber: null,
  signupCode: '',
  referralCode: '',
  sourceOfFunds: '',
  sourceOfIncome: '',
  dateOfBirth: new Date('01-01-1000'),
});
const errorCode = ref<string>('');
const errorRef = ref<string>('');
const fieldErrors = ref<IFieldErrors>({
  username: '',
  firstname: '',
  lastname: '',
  dob: '',
  email: '',
  language: '',
  idNumber: '',
  idNumberType: '',
  sourceFunds: '',
});

const schema = ref<Record<string, VueformSchema>>({
  username: {
    type: 'text',
    disabled: true,
    floating: $t('username'),
    placeholder: $t('username'),
  },
  firstname: {
    type: 'text',
    disabled: true,
    floating: $t('first-name'),
    placeholder: $t('first-name'),
  },
  lastname: {
    type: 'text',
    disabled: true,
    floating: $t('last-name'),
    placeholder: $t('last-name'),
  },
  dateOfBirth: {
    type: 'text',
    disabled: true,
    floating: $t('date-of-birth'),
    placeholder: $t('date-of-birth'),
  },
  idNumber: {
    type: 'text',
    disabled: true,
    floating: $t('id-number'),
    placeholder: $t('id-number'),
  },
  sourceOfFunds: {
    type: 'text',
    disabled: true,
    floating: $t('source-of-income'),
    placeholder: $t('source-of-income'),
  },
});

const updatableSchema = ref<Record<string, VueformSchema>>({
  email: {
    type: 'text',
    floating: $t('email'),
    placeholder: $t('email'),
  },
});

const site = useSiteStore();

const displayEmail = computed(() =>
  site.getFeatureFlags.get('website.enableemail'),
);
const displayID = computed(() => site.getFeatureFlags.get('website.enableid'));
const displaySourceOfFunds = computed(() =>
  site.getFeatureFlags.get('website.enablesof'),
);

const filterFormSchema = () => {
  if (!displayID.value) delete schema.value.idNumber;

  if (!displaySourceOfFunds.value) delete schema.value.sourceOfFunds;
};

const mobilePopUp = ref(null);
onClickOutside(mobilePopUp, (event) => (active.value = false));

function emailVerification(email: string): boolean {
  var emailStatus = new RegExp(
    '^[A-Za-z0-9\\s*._%+-]+@[A-Za-z0-9\\s*.-]+\\.[A-Za-z\\s*]{2,}$',
    'i',
  );
  return emailStatus.test(email);
}

function formatBirthday() {
  const dob = userDetails.value.dateOfBirth;
  userDetails.value.dateOfBirth = new Date(dob).toLocaleDateString('en-GB');
}

function createLangSelection() {
  const cultures = !!site.getConfig.cultures
    ? site.getConfig.cultures
    : site.getConfig.Cultures;
  if (!!cultures) {
    localeStore.setLocaleOptions(cultures);
    langOptions.value = localeStore.getLocaleOptions;
  }
}

onMounted(async () => {
  filterFormSchema();
  await populateUserDetails();
});

async function update() {
  errorCode.value = '';
  const userData: any = { ...userDetails.value };

  if (userData.signupCode == '') delete userData.signupCode;

  if (userData.referralCode == '') delete userData.referralCode;

  delete userData.idNumber;
  delete userData.idNumberType;

  const emailStatus: boolean = emailVerification(userData.email);
  if (!emailStatus) {
    fieldErrors.value.email = errorsObject.value.isEmailValid;
  } else {
    fieldErrors.value.email = '';
  }
  const isEmpty = Object.values(fieldErrors.value).find((item) => {
    return item != '';
  });

  if (!isEmpty) {
    activeLoader.value = true;

    // dynamically add fields to array to pass through to update
    const request = {
      id: userData.id,
      username: userData.username,
      countryCode: userData.countryCode,
      email: userData.email,
    };

    // send GA tracking response to update user details
    await trackingMethods.myAccountDetailsUpdate();

    await $authService
      .editUser(request)
      .then((data) => {
        populateUserDetails();
        activeLoader.value = false;
        if (data?.isSuccessful) {
          //If Successful
          toast.add({
            severity: 'success',
            summary: $t('success'),
            detail: $t(`pers-details-updated-succ`),
            life: 3000,
            group: 'toast',
          });
        } else {
          errorCode.value = data?.error?.displayCode + '';
          errorRef.value = data?.error?.code + '';
        }
      })
      .catch((e) => {
        activeLoader.value = false;
        errorCode.value = e?.error?.displayCode || e?.error?.code;
      });
  }
}

async function populateUserDetails() {
  activeLoader.value = true;
  try {
    await $authService.getUserExtended().then(({ data }) => {
      userDetails.value = { ...userDetails.value, ...data };
      formatBirthday();
      primaryNo.value = data?.primaryMobileNumber || '';
      mobileNo.push(primaryNo.value);
      data?.secondaryMobileNumbers?.forEach((no) => {
        mobileNo.push(no);
      });
    });
    activeLoader.value = false;
  } catch (error) {
    activeLoader.value = false;
    console.error(error);
  }
  createLangSelection();
}
/**
 * TODO:
 * What happens when a language gets changed (fire an event to translate whole site?) - check hamburger dropdown
 * Check with business what other fields going to be added and map accordingly
 * Error messages!
 */
</script>

<template>
  <Toast position="bottom-center" group="toast" :pt="toastPassThrough" />
  <UserSettingsBaseContainer
    :title="$t('personal-details')"
    title-class="sm:px-3"
    miniContainer
  >
    <LazyUserInterfaceGenericLoader v-if="activeLoader" :standalone="true" />

    <div v-else class="personal-details">
      <div class="w-full sm:px-3">
        <Vueform
          :schema="schema"
          :model-value="userDetails"
          sync
          class="mb-2"
        />
        <div v-if="displayEmail">
          <hr
            class="w-full border-light-200 dark:border-dark-900 absolute left-0"
          />
          <p class="mb-2 text-base font-bold mt-4">
            {{ $t('update-email') }}
          </p>
          <Vueform :schema="updatableSchema" :model-value="userDetails" sync />
          <Button class="w-full justify-center mt-3" @click="update()">
            {{ $t('update') }}
          </Button>
        </div>
      </div>
    </div>
  </UserSettingsBaseContainer>
  <GenericError
    v-if="errorCode"
    state="danger"
    class="max-w-72 mx-auto mb-2"
    :reference-code="errorRef"
  >
    {{ $t(`update-details-${errorCode}`) }}
  </GenericError>
</template>

<style scoped lang="scss">
.popUpOptions {
  width: 328px;
  z-index: 100;
  margin: 40px 0 0 52px;
}

.dot {
  height: 8px;
  width: 8px;
  background-color: var(--primary-bluebase);
  border-radius: 100%;
  position: absolute;
  right: 198px;
  top: 10px;
}

/** Breakpoints  */

@media screen and (max-width: 575px) {
  .dot {
    right: 88px;
    top: 13px;
  }

  .popUpOptions {
    width: 250px;
    margin: 37px 0 0 20px;
  }
}

@media screen and (max-width: 359px) {
  .dot {
    right: 48px;
    top: 13px;
  }

  .popUpOptions {
    width: 200px;
    margin: 37px 0 0 31px;
  }
}

.dark {
  .spike {
    border-top: solid #464f60;
    border-left: solid #464f60;
  }
}

.spike {
  height: 10px;
  width: 10px;
  background: #ffffff;
  transform: rotate(45deg);
  border-radius: 0 0 2px 0;
  border-top-width: 1px !important;
  border-left-width: 1px !important;
  border-top: solid #dae0ed;
  border-left: solid #dae0ed;
  position: absolute;
  right: 0.7em;
  bottom: 2.69em;
}
</style>

<style lang="scss">
.personal-details .form-bg-disabled {
  .dark & {
    background-color: #131316;
    border: none;
    color: #8a8b9c;
  }
}

/* Remove up down arrow from input type number*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.personal-details {
  & .p-disabled {
    opacity: 1;
  }

  & label.input-label {
    color: #8a8b9c;
  }

  & input.p-inputtext {
    background: #dae0ed;
    color: #8a8b9c;
    -webkit-text-fill-color: #8a8b9c;
    border: none;
    font-weight: 500;
    font-size: 16px;
    width: 100%;
  }

  & .form-p-input {
    color: #8a8b9c;
    -webkit-text-fill-color: #8a8b9c;
    border: none;
    font-weight: 500;
    font-size: 16px;
    width: 100%;
  }
}

.dark .personal-details {
  & label.input-label {
    color: #8a8b9c;
  }

  & input.p-inputtext {
    background: #121417;
    color: #8a8b9c;
    -webkit-text-fill-color: #8a8b9c;
  }

  & .form-p-input {
    color: #8a8b9c;
    -webkit-text-fill-color: #8a8b9c;
    width: 100%;
    border: none;
    font-weight: 500;
    font-size: 16px;
  }
}
</style>
